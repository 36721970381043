/**
 * @param {string} url
 * @param params
 * @returns {string}
 */
export const computeApiCacheKey = (url, params = {}) => {
  const searchParams = new URLSearchParams(params)

  searchParams.forEach((value, key) => {
    if (typeof value === 'undefined' || value === 'null' || value === '') {
      searchParams.delete(key)
    }
  })

  const query = searchParams.toString()

  return `${url}${query.length > 0 ? '?' + query : ''}`
}
